var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row"},[(!_vm.hideFiltros)?_c('div',{staticClass:"col-5 pr-1"},[_c('b-form-group',{attrs:{"label":"Periodo"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6 pr-1"},[_c('b-input',{attrs:{"type":"date","readonly":_vm.loading},model:{value:(_vm.filtros.dt_i),callback:function ($$v) {_vm.$set(_vm.filtros, "dt_i", $$v)},expression:"filtros.dt_i"}})],1),_c('div',{staticClass:"col-6 pl-1"},[_c('b-input',{attrs:{"type":"date","readonly":_vm.loading},model:{value:(_vm.filtros.dt_f),callback:function ($$v) {_vm.$set(_vm.filtros, "dt_f", $$v)},expression:"filtros.dt_f"}})],1)])])],1):_vm._e(),_c('div',{staticClass:"col-3 px-1"},[_c('SelectEmpresaClinica',{attrs:{"clinicaInicialID":_vm.clinica,"required":false,"autoBlock":false,"disabled":_vm.loading},on:{"empresa":_vm.selectEmpresa}})],1),_c('div',{staticClass:"col-4 d-flex pl-1"},[_c('b-form-group',{attrs:{"label":"Situação"}},[_c('b-select',{attrs:{"options":[
            'Todos',
            'Ativos',
            'Concluídos',
            'Pago e não vinculado',
            'Expirados',
            'Devolvido',
          ],"disabled":_vm.loading},model:{value:(_vm.filtros.situacao),callback:function ($$v) {_vm.$set(_vm.filtros, "situacao", $$v)},expression:"filtros.situacao"}})],1),_c('b-btn',{staticClass:"ml-1 mt-4",staticStyle:{"height":"38px"},attrs:{"variant":"primary","block":"","disabled":_vm.loading},on:{"click":_vm.carregar}},[_c('b-icon',{attrs:{"icon":"search"}}),_vm._v(" Buscar")],1)],1),(!_vm.loading && _vm.lista.length > 0)?_c('div',{staticClass:"col-12"},[_c('b-form-group',{attrs:{"label":"Filtrar Resultados"}},[_c('b-input',{attrs:{"placeholder":"localize por ID, Cliente ou Valor..."},model:{value:(_vm.filtro),callback:function ($$v) {_vm.filtro=$$v},expression:"filtro"}})],1)],1):_vm._e(),(_vm.selectable)?_c('div',{staticClass:"col-12"},[_c('small',[_vm._v(" Clique no pix para selecionar ")])]):_vm._e()]),_c('hr'),_c('div',{staticClass:"w-100 text-right"},[_c('b-btn',{attrs:{"type":"button","disabled":_vm.loading},on:{"click":_vm.print}},[_c('b-icon-printer'),_vm._v(" Imprimir")],1)],1),(!_vm.loading && !_vm.carregado)?_c('div',{staticClass:"text-center p-5"},[_vm._v(" Selecione um filtro e clique em BUSCAR para visualizar os lançamentos PIX. "),_c('div',{staticStyle:{"max-width":"300px","margin":"0 auto"}},[_c('b-btn',{staticClass:"ml-1 mt-4",staticStyle:{"height":"38px"},attrs:{"variant":"primary","block":""},on:{"click":_vm.carregar}},[_c('b-icon',{attrs:{"icon":"search"}}),_vm._v(" Buscar")],1)],1)]):_vm._e(),(_vm.loading)?_c('div',{staticClass:"text-center p-5"},[_c('b-spinner'),_c('br'),_vm._v(" aguarde... ")],1):_vm._e(),(_vm.carregado)?_c('div',{attrs:{"id":"printDiv"}},[_c('print-component',{staticStyle:{"max-width":"700px","margin":"0 auto"},attrs:{"titulo":`Lançamentos PIX`,"filtros":`Data: ${this.filtros.dt_i_desc} á ${
        this.filtros.dt_f_desc
      } | Clínica: ${
        !this.filtros.clinica_desc ? 'Todos' : this.filtros.clinica_desc
      } | Situação: ${_vm.filtros.situacao} ${
        _vm.filtro && _vm.filtro != '' ? ' | Filtro: ' + _vm.filtro : ''
      }`,"show":true}},[(!_vm.loading)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('b-table',{style:(`font-size:11px`),attrs:{"striped":"","hover":"","items":_vm.lista,"filter":_vm.filtro,"selectable":_vm.selectable,"select-mode":"single","small":"","fields":[
              { key: 'id', label: 'ID' },
              { key: 'data', label: 'Data/Hora' },
              {
                key: 'clinica.sigla',
                label: 'Clínica',
                class:
                  _vm.filtros.clinica_id && _vm.filtros.clinica_id > 0
                    ? 'd-none'
                    : '',
              },
              { key: 'status', label: 'Status' },
              { key: 'venda.venda_id', label: 'Venda' },
              { key: 'devedor_nome', label: 'Cliente' },
              { key: 'valor', label: 'Valor' },
              { key: 'pixEndToEndCode', label: 'Cod. Trans' },
            ]},on:{"row-selected":_vm.onRowSelected},scopedSlots:_vm._u([{key:"cell(id)",fn:function(row){return [_vm._v(" "+_vm._s(row.item.id)+" "),_c('div',{staticStyle:{"font-size":"8px"}},[_vm._v(_vm._s(row.item.txId))])]}},{key:"cell(data)",fn:function(row){return [_vm._v(" "+_vm._s(_vm._f("moment")(row.item.createdAt,"DD/MM/YYYY HH:mm"))+" ")]}},{key:"cell(valor)",fn:function(row){return [_vm._v(" "+_vm._s(_vm._f("currency")(row.item.valor))+" ")]}}],null,false,1266615065)})],1),(!_vm.selectable)?_c('div',{staticClass:"col-12 mt-1"},[_c('hr'),_c('div',{staticClass:"row text-center"},[_c('div',{staticClass:"col-3 col-sm-3 pr-1"},[_c('b-card',{attrs:{"title":"Todos"}},[_c('h3',[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.lista.reduce((ret, vl) => { ret += vl.valor; return ret; }, 0)))+" ")])])],1),_c('div',{staticClass:"col-3 col-sm-3 pl-1 pr-1"},[_c('b-card',{attrs:{"title":"Concluídos"}},[_c('h3',[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.lista.reduce((ret, vl) => { ret += vl.status == "PAGO" && vl.venda ? vl.valor : 0; return ret; }, 0)))+" ")])])],1),_c('div',{staticClass:"col-3 col-sm-3 pl-1 pr-1"},[_c('b-card',{attrs:{"title":"Não vinculado"}},[_c('h3',[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.lista.reduce((ret, vl) => { ret += vl.status == "PAGO" && !vl.venda ? vl.valor : 0; return ret; }, 0)))+" ")])])],1),_c('div',{staticClass:"col-3 col-sm-3 pl-1"},[_c('b-card',{attrs:{"title":"Expirados"}},[_c('h3',[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.lista.reduce((ret, vl) => { ret += vl.status == "EXPIRADO" ? vl.valor : 0; return ret; }, 0)))+" ")])])],1)])]):_vm._e()]):_vm._e()])],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }